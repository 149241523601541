import React, { useState, useEffect } from 'react';
import { API_URL } from '../constants';

const RankSystem = () => {
  const [rankings, setRankings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        setLoading(true);
        // API çağrısında hata ayıklama için console.log ekleyelim
        console.log('Fetching rankings from:', `${API_URL}/api/players`);
        
        const response = await fetch(`${API_URL}/api/players`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        // Response durumunu kontrol edelim
        console.log('Response status:', response.status);
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Received data:', data); // Gelen veriyi kontrol edelim
        
        // Eğer data.players bir array değilse düzeltelim
        const playersList = Array.isArray(data.players) ? data.players : 
                          Array.isArray(data) ? data : [];
        
        setRankings(playersList);
      } catch (err) {
        console.error('Error fetching rankings:', err);
        setError('Failed to load rankings. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchRankings();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-amber-300 text-xl">Loading rankings...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  // Boş data kontrolü ekleyelim
  if (!rankings || rankings.length === 0) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-amber-300 text-xl">No rankings available yet.</div>
      </div>
    );
  }

  return (
    <div className="relative z-20 container mx-auto px-4 py-8">
      <div className="text-center mb-12">
        <h1 className="text-6xl font-bold text-amber-300 mb-8 tracking-wider">
          WARRIOR RANKINGS
        </h1>
      </div>

      <div className="max-w-[1200px] mx-auto bg-gradient-to-b from-gray-900/90 to-gray-950/90 p-8 border-4 border-amber-700">
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b-2 border-amber-700">
                <th className="px-6 py-4 text-left text-amber-300">Rank</th>
                <th className="px-6 py-4 text-left text-amber-300">Warrior</th>
                <th className="px-6 py-4 text-left text-amber-300">Type</th>
                <th className="px-6 py-4 text-left text-amber-300">Wins</th>
                <th className="px-6 py-4 text-left text-amber-300">Losses</th>
                <th className="px-6 py-4 text-left text-amber-300">Win Rate</th>
              </tr>
            </thead>
            <tbody>
              {rankings.map((player, index) => (
                <tr key={player._id || index}
                    className="border-b border-amber-700/30 hover:bg-amber-900/20">
                  <td className="px-6 py-4 text-amber-300">{player.rank || index + 1}</td>
                  <td className="px-6 py-4 text-white">{player.steamId || 'Unknown'}</td>
                  <td className="px-6 py-4 text-white">{player.playType || 'Unknown'}</td>
                  <td className="px-6 py-4 text-green-400">{player.wins || 0}</td>
                  <td className="px-6 py-4 text-red-400">{player.losses || 0}</td>
                  <td className="px-6 py-4 text-amber-300">
                    {calculateWinRate(player.wins, player.losses)}%
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

// Win rate hesaplama fonksiyonu
const calculateWinRate = (wins = 0, losses = 0) => {
  const total = wins + losses;
  if (total === 0) return '0.0';
  return ((wins / total) * 100).toFixed(1);
};

export default RankSystem;