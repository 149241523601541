import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SoundContext } from '../App';

const Navbar = () => {
  const navigate = useNavigate();
  const { playSound, isMuted, setIsMuted } = useContext(SoundContext);

  const handleNavigation = (path, soundNumber) => {
    playSound(soundNumber);
    navigate(path);
  };

  return (
    <nav className="relative z-30">
      <div className="container mx-auto px-4 py-6">
        <div className="flex justify-between items-center">
          <div className="flex justify-center items-center gap-6 flex-1">
            <button
              onClick={() => handleNavigation('/join', 1)}
              className="px-6 py-3 bg-gradient-to-b from-amber-700 to-amber-900 text-amber-300 text-lg font-bold transform hover:scale-105 transition-all duration-300 border-2 border-amber-600"
              style={{ clipPath: 'polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0 50%)' }}
            >
              JOIN BATTLE
            </button>

            <button
              onClick={() => handleNavigation('/', 2)}
              className="px-6 py-3 bg-gradient-to-b from-amber-700 to-amber-900 text-amber-300 text-lg font-bold transform hover:scale-105 transition-all duration-300 border-2 border-amber-600"
              style={{ clipPath: 'polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0 50%)' }}
            >
              TOURNAMENT
            </button>

            <button
              onClick={() => handleNavigation('/warriors', 3)}
              className="px-6 py-3 bg-gradient-to-b from-amber-700 to-amber-900 text-amber-300 text-lg font-bold transform hover:scale-105 transition-all duration-300 border-2 border-amber-600"
              style={{ clipPath: 'polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0 50%)' }}
            >
              WARRIORS
            </button>

            <button
              onClick={() => handleNavigation('/ranks', 4)}
              className="px-6 py-3 bg-gradient-to-b from-amber-700 to-amber-900 text-amber-300 text-lg font-bold transform hover:scale-105 transition-all duration-300 border-2 border-amber-600"
              style={{ clipPath: 'polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0 50%)' }}
            >
              RANKINGS
            </button>

            <button
              onClick={() => handleNavigation('/rewards', 5)}
              className="px-6 py-3 bg-gradient-to-b from-amber-700 to-amber-900 text-amber-300 text-lg font-bold transform hover:scale-105 transition-all duration-300 border-2 border-amber-600"
              style={{ clipPath: 'polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0 50%)' }}
            >
              REWARDS
            </button>
          </div>

          <div className="flex items-center gap-4">
            <a
              href="https://steamcommunity.com/id/keywog/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => playSound(6)}
              className="flex items-center gap-2 px-4 py-2 bg-gradient-to-b from-amber-700 to-amber-900 text-amber-300 border-2 border-amber-600 hover:scale-105 transition-all duration-300"
            >
              <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 0C5.4 0 0 5.4 0 12c0 5.1 3.2 9.5 7.6 11.2l2.4-3.6c-2.4-1.1-4-3.5-4-6.3 0-3.9 3.1-7 7-7s7 3.1 7 7c0 2.8-1.7 5.2-4 6.3l2.4 3.6C22.8 21.5 24 17.1 24 12c0-6.6-5.4-12-12-12z" />
              </svg>
              <span>Tournament Host</span>
            </a>

            <button
              onClick={() => {
                if (!isMuted) playSound(7);
                setIsMuted(!isMuted);
              }}
              className={`p-3 rounded-full ${isMuted ? 'bg-red-700' : 'bg-green-700'} transition-colors duration-300`}
            >
              {isMuted ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;