import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createContext, useState, useEffect, useRef } from 'react';
import TournamentBracket from './components/honor';
import JoinBattle from './components/join';
import Warriors from './components/warriors';
import Navbar from './components/navbar';
import Rewards from './components/rewards';
import RankSystem from './components/rank';
import AdminPanel from './components/admin';

export const SoundContext = createContext(null);

function App() {
  const [isMuted, setIsMuted] = useState(false);
  const backgroundMusicRef = useRef(new Audio('/medieval/medieval.mp3'));

  useEffect(() => {
    const bgMusic = backgroundMusicRef.current;
    bgMusic.loop = true;
    bgMusic.volume = 0.1;

    const handleUserInteraction = () => {
      if (!isMuted) {
        bgMusic.play().catch(console.error);
      }
      document.removeEventListener('click', handleUserInteraction);
    };

    document.addEventListener('click', handleUserInteraction);

    return () => {
      bgMusic.pause();
      document.removeEventListener('click', handleUserInteraction);
    };
  }, []);

  useEffect(() => {
    const bgMusic = backgroundMusicRef.current;
    if (isMuted) {
      bgMusic.pause();
    } else {
      bgMusic.play().catch(console.error);
    }
  }, [isMuted]);

  const playSound = (soundNumber) => {
    if (!isMuted) {
      const audio = new Audio(`/medieval/${soundNumber}.mp3`);
      audio.volume = 1.0; // Ses efektlerinin volume'unu düşürdüm
      audio.play().catch(console.error);
    }
  };

  return (
    <SoundContext.Provider value={{ isMuted, setIsMuted, playSound }}>
      <Router>
        <div className="min-h-screen bg-cover bg-center bg-fixed relative"
             style={{ backgroundImage: 'url(/honor.jpg)' }}>
          <div className="absolute inset-0 bg-black bg-opacity-50" />
          <Navbar />
          <Routes>
            <Route path="/" element={<TournamentBracket />} />
            <Route path="/join" element={<JoinBattle />} />
            <Route path="/warriors" element={<Warriors />} />
            <Route path="/ranks" element={<RankSystem />} />
            <Route path="/rewards" element={<Rewards />} />
            <Route path="/admin" element={<AdminPanel />} />
          </Routes>
        </div>
      </Router>
    </SoundContext.Provider>
  );
}

export default App;