import React, { useState, useEffect } from 'react';
import { API_URL, ENDPOINTS, DEFAULT_FETCH_OPTIONS } from '../constants';

const Warriors = () => {
  const [playersData, setPlayersData] = useState({ players: [], currentPage: 1, totalPages: 1 });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await fetch(`${API_URL}${ENDPOINTS.PLAYERS}`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setPlayersData(data);
      } catch (error) {
        console.error('Error fetching players:', error);
        setError('Failed to load warriors');
      } finally {
        setLoading(false);
      }
    };

    fetchPlayers();
  }, []);

  if (loading) {
    return (
      <div className="relative z-20 container mx-auto px-4 py-8 text-center">
        <div className="text-amber-300 text-xl">Loading warriors...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="relative z-20 container mx-auto px-4 py-8 text-center">
        <div className="text-red-400 text-xl">{error}</div>
      </div>
    );
  }

  return (
    <div className="relative z-20 container mx-auto px-4 py-8">
      <div className="text-center mb-12">
        <h1 className="text-6xl font-bold text-amber-300 mb-8 tracking-wider" 
            style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.5), 0 0 20px rgba(255,191,0,0.3)' }}>
          REGISTERED WARRIORS
        </h1>
      </div>

      <div className="max-w-[1200px] mx-auto bg-gradient-to-b from-gray-900/90 to-gray-950/90 p-8 border-4 border-amber-700"
           style={{ 
             boxShadow: '0 0 40px rgba(217,119,6,0.2)',
             backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239c7525' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")"
           }}>
        <div className="space-y-6">
          {playersData.players.map((player, index) => (
            <div key={index} 
                 className="bg-gradient-to-r from-gray-900/90 to-gray-800/90 border-2 border-amber-700/70 p-1 relative group hover:border-amber-500/70 transition-colors duration-300">
              <div className="p-6">
                {/* Header */}
                <div className="flex justify-between items-center mb-4 border-b border-amber-700/30 pb-4">
                  <div className="flex items-center gap-4">
                    <span className={`text-2xl font-bold ${player.playType === 'solo' ? 'text-amber-300' : 'text-amber-400'}`}>
                      {player.playType === 'solo' ? 'Solo Warrior' : 'Team Legion'}
                    </span>
                    <span className="px-4 py-1 bg-amber-900/30 text-amber-300 text-sm rounded-full">
                      Steam ID: {player.steamId}
                    </span>
                  </div>
                  <span className="text-amber-400/80 text-sm">
                    {new Date(player.registrationDate).toLocaleDateString()}
                  </span>
                </div>

                {/* Team Members */}
                {player.playType === 'team' && player.teamMembers && player.teamMembers.length > 0 && (
                  <div className="space-y-2 mb-4">
                    <h3 className="text-amber-300 font-semibold mb-2">Team Members:</h3>
                    <div className="grid grid-cols-2 gap-4">
                      {player.teamMembers.map((member, idx) => (
                        <div key={idx} 
                             className="flex items-center gap-2 bg-gray-800/50 p-3 rounded border border-amber-700/30">
                          <svg xmlns="http://www.w3.org/2000/svg" 
                               className="h-5 w-5 text-amber-500" 
                               viewBox="0 0 20 20" 
                               fill="currentColor">
                            <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                          </svg>
                          <span className="text-amber-100">{member.steamId}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Message */}
                {player.message && (
                  <div className="mt-4 bg-gray-900/50 p-4 rounded border border-amber-700/30">
                    <p className="text-amber-200 italic">"{player.message}"</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Pagination */}
        {playersData.totalPages > 1 && (
          <div className="mt-8 flex justify-center gap-2">
            {Array.from({ length: playersData.totalPages }, (_, i) => i + 1).map((page) => (
              <button
                key={page}
                className={`px-4 py-2 rounded ${
                  page === playersData.currentPage
                    ? 'bg-amber-700 text-white'
                    : 'bg-gray-800 text-amber-300 hover:bg-amber-700/50'
                }`}
                onClick={() => {
                  // Add pagination handling here
                }}
              >
                {page}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Warriors;