import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SoundContext } from '../App';
import { API_URL, ENDPOINTS, DEFAULT_FETCH_OPTIONS } from '../constants';

const JoinBattle = () => {
  const [playType, setPlayType] = useState('');
  const [steamId, setSteamId] = useState('');
  const [teamMembers, setTeamMembers] = useState([{ steamId: '' }]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { playSound } = useContext(SoundContext);
  
  const handleAddMember = () => {
    if (teamMembers.length < 5) {
      playSound(6);
      setTeamMembers([...teamMembers, { steamId: '' }]);
    }
  };

  const handleRemoveMember = (index) => {
    if (teamMembers.length > 1) {
      playSound(7);
      const newMembers = teamMembers.filter((_, i) => i !== index);
      setTeamMembers(newMembers);
    }
  };

  const handlePlayTypeSelect = (type) => {
    playSound(8);
    setPlayType(type);
    setError(''); // Clear any existing errors
  };

  const validateInputs = () => {
    if (!playType) {
      setError('Please select your path: Solo Warrior or Team Legion');
      return false;
    }

    if (!steamId.trim()) {
      setError('Please enter your Steam ID');
      return false;
    }

    if (steamId.length > 100) {
      setError('Steam ID is too long');
      return false;
    }

    if (playType === 'team') {
      const invalidTeamMembers = teamMembers.some(member => !member.steamId.trim());
      if (invalidTeamMembers) {
        setError('Please enter Steam ID for all team members');
        return false;
      }

      const tooLongTeamMembers = teamMembers.some(member => member.steamId.length > 100);
      if (tooLongTeamMembers) {
        setError('One or more team member Steam IDs are too long');
        return false;
      }
    }

    if (message && message.length > 500) {
      setError('Message is too long (maximum 500 characters)');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!validateInputs()) {
      playSound(5);
      return;
    }

    setIsSubmitting(true);
  
    try {
      const response = await fetch(`${API_URL}${ENDPOINTS.REGISTER}`, {
        method: 'POST',
        ...DEFAULT_FETCH_OPTIONS,
        body: JSON.stringify({
          playType,
          steamId: steamId.trim(),
          teamMembers: playType === 'team' ? teamMembers.map(m => ({ steamId: m.steamId.trim() })) : [],
          message: message.trim()
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Registration failed');
      }

      const data = await response.json();
      playSound(4);
      alert('Registration successful! Welcome to the tournament!');
      navigate('/warriors');

    } catch (error) {
      console.error('Registration error:', error);
      playSound(5);
      setError('Registration failed. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <div className="min-h-screen bg-cover bg-center bg-fixed" style={{ backgroundImage: 'url(/honor.jpg)' }}>
      <div className="absolute inset-0 bg-black bg-opacity-60" />
      
      <div className="relative z-10 container mx-auto px-4 py-16">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-5xl font-bold text-amber-300 text-center mb-12"
              style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}>
            Join The Battle
          </h1>

          {error && (
            <div className="mb-8 p-4 bg-red-900/80 text-red-200 border border-red-700 rounded text-center">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="bg-gray-900/90 border-2 border-amber-700 p-8 rounded-lg shadow-2xl">
            {/* Play Type Selection */}
            <div className="mb-8">
              <label className="block text-amber-300 text-xl mb-4">Choose Your Path</label>
              <div className="flex gap-4">
                <button
                  type="button"
                  onClick={() => handlePlayTypeSelect('solo')}
                  className={`flex-1 py-3 px-6 rounded border-2 ${
                    playType === 'solo' 
                      ? 'bg-amber-700 border-amber-500 text-white' 
                      : 'border-amber-700 text-amber-300 hover:border-amber-500'
                  } transition-all duration-300`}
                >
                  Solo Warrior
                </button>
                <button
                  type="button"
                  onClick={() => handlePlayTypeSelect('team')}
                  className={`flex-1 py-3 px-6 rounded border-2 ${
                    playType === 'team' 
                      ? 'bg-amber-700 border-amber-500 text-white' 
                      : 'border-amber-700 text-amber-300 hover:border-amber-500'
                  } transition-all duration-300`}
                >
                  Team Legion
                </button>
              </div>
            </div>

            {/* Steam ID Input */}
            <div className="mb-8">
              <label className="block text-amber-300 mb-2">Your Steam Name</label>
              <input
                type="text"
                value={steamId}
                onChange={(e) => {
                  setSteamId(e.target.value);
                  setError('');
                }}
                maxLength={100}
                className="w-full bg-gray-800 border-2 border-amber-700 rounded p-3 text-white focus:border-amber-500 focus:outline-none"
                placeholder="Enter your Steam Name"
                required
              />
              <p className="text-amber-400/60 text-sm mt-1">{steamId.length}/100 characters</p>
            </div>

            {/* Team Members */}
            {playType === 'team' && (
              <div className="mb-8">
                <label className="block text-amber-300 mb-4">Team Members</label>
                {teamMembers.map((member, index) => (
                  <div key={index} className="flex gap-4 mb-4">
                    <div className="flex-1">
                      <input
                        type="text"
                        value={member.steamId}
                        onChange={(e) => {
                          const newMembers = [...teamMembers];
                          newMembers[index].steamId = e.target.value;
                          setTeamMembers(newMembers);
                          setError('');
                        }}
                        maxLength={100}
                        className="w-full bg-gray-800 border-2 border-amber-700 rounded p-3 text-white focus:border-amber-500 focus:outline-none"
                        placeholder={`Member ${index + 1} Steam Name`}
                        required
                      />
                      <p className="text-amber-400/60 text-sm mt-1">{member.steamId.length}/100 characters</p>
                    </div>
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => handleRemoveMember(index)}
                        className="px-4 py-2 bg-red-900/80 text-red-300 rounded hover:bg-red-800"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
                {teamMembers.length < 5 && (
                  <button
                    type="button"
                    onClick={handleAddMember}
                    className="w-full py-2 border-2 border-dashed border-amber-700 text-amber-300 rounded hover:border-amber-500 mt-2"
                  >
                    Add Team Member
                  </button>
                )}
              </div>
            )}

            {/* Message Input */}
            <div className="mb-8">
              <label className="block text-amber-300 mb-2">Your Message (Optional)</label>
              <textarea
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  setError('');
                }}
                maxLength={500}
                className="w-full bg-gray-800 border-2 border-amber-700 rounded p-3 text-white focus:border-amber-500 focus:outline-none h-32"
                placeholder="Share your battle cry or any additional information..."
              />
              <p className="text-amber-400/60 text-sm mt-1">{message.length}/500 characters</p>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full py-4 bg-gradient-to-r from-amber-700 to-amber-600 text-white text-xl font-bold rounded 
                ${!isSubmitting ? 'hover:from-amber-600 hover:to-amber-500' : 'opacity-75 cursor-not-allowed'} 
                transition-all duration-300 shadow-lg`}
            >
              {isSubmitting ? 'Submitting...' : 'Submit Your Entry'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default JoinBattle;