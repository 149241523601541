import React, { useState, useEffect } from 'react';
import { API_URL } from '../constants';

const TournamentBracket = () => {
  const [tournamentData, setTournamentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchTournamentData = async () => {
    try {
      setLoading(true);
      setError(null);

      console.log('Fetching tournament data from:', `${API_URL}/api/tournament`); // Debug log

      const response = await fetch(`${API_URL}/api/tournament`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Origin': window.location.origin
        }
      });

      console.log('Response status:', response.status); // Debug log

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Tournament data received:', data); // Debug log

      // Veriyi sırala (roundNumber'a göre)
      const sortedData = Array.isArray(data) 
        ? data.sort((a, b) => (a.roundNumber || 0) - (b.roundNumber || 0))
        : [];

      setTournamentData(sortedData);
    } catch (err) {
      console.error('Error fetching tournament data:', err);
      setError('Failed to load tournament data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTournamentData();
    const interval = setInterval(fetchTournamentData, 30000);
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen relative flex items-center justify-center">
        <div className="text-amber-300 text-xl">Loading tournament data...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen relative flex items-center justify-center">
        <div className="text-red-400 text-xl">{error}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen relative bg-cover bg-center bg-fixed" style={{ backgroundImage: 'url(/honor.jpg)' }}>
      <div className="absolute inset-0 bg-black bg-opacity-50" />

      <div className="relative z-20 p-8 min-h-screen">
        <div className="text-center mb-12">
          <h1 className="text-6xl font-bold text-amber-300 mb-8 tracking-wider" 
              style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.5), 0 0 20px rgba(255,191,0,0.3)' }}>
            KNIGHTS OF HONOR 2 TOURNAMENT
          </h1>
        </div>

        {/* Rules Section */}
        <div className="max-w-[1800px] mx-auto mb-16 bg-gradient-to-b from-gray-900/90 to-gray-950/90 p-8 border-4 border-amber-700"
             style={{ 
               boxShadow: '0 0 40px rgba(217,119,6,0.2)',
               backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239c7525' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")"
             }}>
          <div className="mb-8">
            <h2 className="text-3xl font-bold text-amber-300 mb-6 text-center">RULES OF ENGAGEMENT</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {/* Time and Schedule */}
              <div className="bg-gray-900/70 p-6 border-2 border-amber-700/50 rounded-lg">
                <h3 className="text-xl font-bold text-amber-400 mb-4 flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  Tournament Schedule
                </h3>
                <ul className="space-y-2 text-amber-100">
                  <li>• Battles commence daily at 17:00 GMT+3</li>
                  <li>• Missing a battle results in elimination</li>
                  <li>• Players must check in 30 minutes before battle</li>
                </ul>
              </div>

              {/* Tournament Structure */}
              <div className="bg-gray-900/70 p-6 border-2 border-amber-700/50 rounded-lg">
                <h3 className="text-xl font-bold text-amber-400 mb-4 flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                  </svg>
                  Tournament Structure
                </h3>
                <ul className="space-y-2 text-amber-100">
                  <li>• Separate tournaments for Solo Warriors and Team Legions</li>
                  <li>• Winners advance to higher leagues</li>
                  <li>• Final battles occur within your own team</li>
                </ul>
              </div>

              {/* Combat Rules */}
              <div className="bg-gray-900/70 p-6 border-2 border-amber-700/50 rounded-lg">
                <h3 className="text-xl font-bold text-amber-400 mb-4 flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9" />
                  </svg>
                  Combat Rules
                </h3>
                <ul className="space-y-2 text-amber-100">
                  <li>• Team players battle together until reaching higher leagues</li>
                  <li>• Solo Warriors compete exclusively with other Solo Warriors</li>
                  <li>• Each victory brings honor and advancement</li>
                </ul>
              </div>

              {/* Advancement */}
              <div className="bg-gray-900/70 p-6 border-2 border-amber-700/50 rounded-lg">
                <h3 className="text-xl font-bold text-amber-400 mb-4 flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                  </svg>
                  League Advancement
                </h3>
                <ul className="space-y-2 text-amber-100">
                  <li>• Victory leads to league advancement</li>
                  <li>• Teams must prove their worth in internal battles</li>
                  <li>• Higher leagues offer greater rewards and honor</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Tournament Bracket */}
        <div className="max-w-[1800px] mx-auto bg-gradient-to-b from-gray-900/90 to-gray-950/90 p-12 border-4 border-amber-700"
             style={{ 
               boxShadow: '0 0 40px rgba(217,119,6,0.2)',
               backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239c7525' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")"
             }}>
          <div className="flex justify-between items-start gap-16 relative overflow-x-auto pb-8">
            {tournamentData.map((round, roundIndex) => (
              <div key={round._id} className="flex-1 min-w-[320px]">
                <div className="text-center mb-8">
                  <h2 className="text-2xl font-bold text-amber-300 mb-2">{round.roundName}</h2>
                  <p className="text-amber-400/80 text-sm">
                    {new Date(round.startDate).toLocaleDateString()}
                  </p>
                </div>
                <div className="flex flex-col gap-12 relative">
                  {round.matches.map((match, matchIndex) => (
                    <div 
                      key={matchIndex} 
                      className="relative group"
                      style={{
                        marginTop: roundIndex > 0 ? 
                          `${Math.pow(2, roundIndex + 1) * 2.5}rem` : '0',
                      }}
                    >
                      <div className="bg-gradient-to-r from-gray-900/90 to-gray-800/90 p-1 relative z-10">
                        <div className="border-2 border-amber-700/70 p-4">
                          <div className="space-y-4">
                            <div className="flex items-center justify-between p-3 bg-gradient-to-r from-gray-800/80 to-gray-700/80 group-hover:from-gray-700/80 group-hover:to-gray-600/80 transition-all duration-300">
                              <span className="text-amber-100 font-semibold text-lg">{match.team1 || 'TBD'}</span>
                              <span className="text-amber-300 font-bold bg-gray-900/50 px-4 py-1">
                                {match.completed ? match.team1Score : '-'}
                              </span>
                            </div>
                            <div className="flex items-center justify-between p-3 bg-gradient-to-r from-gray-800/80 to-gray-700/80 group-hover:from-gray-700/80 group-hover:to-gray-600/80 transition-all duration-300">
                              <span className="text-amber-100 font-semibold text-lg">{match.team2 || 'TBD'}</span>
                              <span className="text-amber-300 font-bold bg-gray-900/50 px-4 py-1">
                                {match.completed ? match.team2Score : '-'}
                              </span>
                            </div>
                          </div>
                        </div>

                        {roundIndex < tournamentData.length - 1 && (
                          <>
                            <div className="absolute right-0 top-1/2 h-0.5 bg-amber-600"
                                 style={{ 
                                   width: '4rem',
                                   transform: 'translateX(100%)',
                                 }} />
                            
                            {matchIndex % 2 === 0 && roundIndex < tournamentData.length - 1 && (
                              <div className="absolute w-0.5 bg-amber-600"
                                   style={{
                                     top: '50%',
                                     right: '-4rem',
                                     height: `${Math.pow(2, roundIndex + 1) * 5}rem`,
                                   }} />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TournamentBracket;