import React, { useState, useEffect } from 'react';

const Rewards = () => {
  const [currentPrize, setCurrentPrize] = useState(0);
  const startDate = new Date('2024-12-11T14:00:00.000Z'); // Tournament start date (17:00 GMT+3)

  useEffect(() => {
    const calculatePrize = () => {
      const now = new Date();
      const timeDiff = now - startDate;
      const hoursDiff = timeDiff / (1000 * 60 * 60);
      const sixHourPeriods = Math.floor(hoursDiff / 6);
      const basePrize = 100; // Starting prize pool
      const newPrize = basePrize + (sixHourPeriods * 5);
      setCurrentPrize(newPrize);
    };

    calculatePrize();
    const interval = setInterval(calculatePrize, 60000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative z-20 container mx-auto px-4 py-8">
      <div className="text-center mb-12">
        <h1 className="text-6xl font-bold text-amber-300 mb-8 tracking-wider" 
            style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.5), 0 0 20px rgba(255,191,0,0.3)' }}>
          TOURNAMENT REWARDS
        </h1>
      </div>

      <div className="max-w-[1200px] mx-auto bg-gradient-to-b from-gray-900/90 to-gray-950/90 p-8 border-4 border-amber-700"
           style={{ 
             boxShadow: '0 0 40px rgba(217,119,6,0.2)',
             backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239c7525' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")"
           }}>
        
        {/* Current Prize Pool */}
        <div className="mb-12 text-center p-8 bg-gradient-to-r from-amber-900/30 to-amber-800/30 border-2 border-amber-600">
          <h2 className="text-4xl font-bold text-amber-300 mb-4">Current Prize Pool</h2>
          <div className="text-6xl font-bold text-amber-400 mb-4">
            ${currentPrize}
          </div>
          <p className="text-amber-200 text-lg">
          Increase the Reward by Donating!
          </p>
        </div>

        {/* Donation Information */}
        <div className="grid md:grid-cols-2 gap-8">
          <div className="bg-gray-900/80 p-6 border-2 border-amber-700/50 rounded-lg">
            <h3 className="text-2xl font-bold text-amber-300 mb-4">Support the Tournament</h3>
            <p className="text-amber-100 mb-4">
              Want to contribute to the prize pool? You can make a donation by following these steps:
            </p>
            <ol className="text-amber-200 space-y-4">
              <li>1. Visit our Tournament Host's Steam profile:
                <a 
                  href="https://steamcommunity.com/id/keywog/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block mt-2 text-amber-400 hover:text-amber-300 underline"
                >
                  https://steamcommunity.com/id/keywog/
                </a>
              </li>
              <li>2. Send a message with the text "TheKoswoga"</li>
              <li>3. Our team will contact you with donation details</li>
            </ol>
          </div>

          <div className="bg-gray-900/80 p-6 border-2 border-amber-700/50 rounded-lg">
            <h3 className="text-2xl font-bold text-amber-300 mb-4">Prize Distribution</h3>
            <div className="space-y-4">
              <div className="flex justify-between items-center p-3 bg-amber-900/30 rounded">
                <span className="text-amber-200">First Place</span>
                <span className="text-amber-300 font-bold">60% of Prize Pool</span>
              </div>
              <div className="flex justify-between items-center p-3 bg-amber-900/20 rounded">
                <span className="text-amber-200">Second Place</span>
                <span className="text-amber-300 font-bold">30% of Prize Pool</span>
              </div>
              <div className="flex justify-between items-center p-3 bg-amber-900/10 rounded">
                <span className="text-amber-200">Third Place</span>
                <span className="text-amber-300 font-bold">10% of Prize Pool</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rewards;