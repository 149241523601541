import React, { useState, useEffect } from 'react';
import { API_URL } from '../constants';

const AdminPanel = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [activeTab, setActiveTab] = useState('tournament');
  const [error, setError] = useState('');
  
  // Tournament State
  const [tournamentData, setTournamentData] = useState([]);
  const [newRound, setNewRound] = useState({
    roundName: '',
    startDate: '',
    matches: []
  });
  
  // Player Management State
  const [players, setPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [rankUpdate, setRankUpdate] = useState({
    rank: '',
    wins: '',
    losses: ''
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/api/admin/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          username: 'admin',
          password: password
        })
      });
  
      if (!response.ok) {
        throw new Error('Login failed');
      }
  
      const data = await response.json();
      localStorage.setItem('adminToken', data.token);
      setIsAuthenticated(true);
      setError('');
      
      // Fetch data after successful login
      fetchTournamentData();
      fetchPlayers();
    } catch (error) {
      console.error('Login error:', error);
      setError('Login failed. Please check your credentials.');
    }
  };

  const handleUpdatePlayer = async () => {
    if (!selectedPlayer?._id) {
      setError('No player selected');
      return;
    }
  
    try {
      const token = localStorage.getItem('adminToken');
      if (!token) throw new Error('No authentication token found');
  
      const response = await fetch(`${API_URL}/api/players/${selectedPlayer._id}/stats`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        body: JSON.stringify({
          wins: parseInt(rankUpdate.wins) || 0,
          losses: parseInt(rankUpdate.losses) || 0,
          rank: parseInt(rankUpdate.rank) || 0
        })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 401) {
          // Token geçersiz ise kullanıcıyı logout yapalım
          handleLogout();
          throw new Error('Session expired. Please login again.');
        }
        throw new Error(errorData.error || 'Failed to update player');
      }
  
      await fetchPlayers();
      setSelectedPlayer(null);
      setRankUpdate({ rank: '', wins: '', losses: '' });
      setError('');
    } catch (error) {
      setError(error.message);
      console.error('Update player error:', error);
    }
  };

  const handleSaveRound = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      if (!token) throw new Error('No authentication token found');

      if (!newRound.roundName || !newRound.startDate || newRound.matches.length === 0) {
        throw new Error('Please fill all required fields');
      }

      const response = await fetch(`${API_URL}/api/tournament`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          roundName: newRound.roundName,
          startDate: newRound.startDate,
          matches: newRound.matches.map(match => ({
            team1: match.team1,
            team2: match.team2,
            team1Score: 0,
            team2Score: 0,
            completed: false
          }))
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to save round');
      }

      await fetchTournamentData();
      setNewRound({
        roundName: '',
        startDate: '',
        matches: []
      });
      setError('');
    } catch (error) {
      setError(error.message);
      console.error('Save round error:', error);
    }
  };

  const fetchTournamentData = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      if (!token) throw new Error('No authentication token found');

      const response = await fetch(`${API_URL}/api/tournament`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) throw new Error('Failed to fetch tournament data');

      const data = await response.json();
      setTournamentData(data);
    } catch (error) {
      setError(error.message);
      console.error('Fetch tournament error:', error);
    }
  };

  const fetchPlayers = async () => {
    try {
      const response = await fetch(`${API_URL}/api/players`);
      if (!response.ok) throw new Error('Failed to fetch players');
      
      const data = await response.json();
      setPlayers(data.players || []);
    } catch (error) {
      setError('Failed to load players');
      console.error('Fetch players error:', error);
    }
  };

  const addNewMatch = () => {
    setNewRound(prev => ({
      ...prev,
      matches: [
        ...prev.matches,
        { team1: '', team2: '', team1Score: 0, team2Score: 0 }
      ]
    }));
  };

  const updateMatch = (index, field, value) => {
    const updatedMatches = [...newRound.matches];
    updatedMatches[index] = {
      ...updatedMatches[index],
      [field]: value
    };
    setNewRound(prev => ({
      ...prev,
      matches: updatedMatches
    }));
  };

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    setIsAuthenticated(false);
    setError('');
  };

  useEffect(() => {
    const token = localStorage.getItem('adminToken');
    if (token) {
      setIsAuthenticated(true);
      fetchTournamentData();
      fetchPlayers();
    }
  }, []);

  if (!isAuthenticated) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900/50">
        <div className="bg-gray-800 p-8 rounded-lg shadow-xl w-96 border-2 border-amber-700 relative z-50">
          <h2 className="text-2xl font-bold text-amber-300 mb-6 text-center">Admin Login</h2>
          {error && (
            <div className="mb-4 p-3 bg-red-900/80 text-red-200 rounded">
              {error}
            </div>
          )}
          <form onSubmit={handleLogin} className="space-y-4">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter admin password"
              className="w-full bg-gray-700 border-2 border-amber-700 rounded p-3 text-white focus:outline-none focus:border-amber-500"
            />
            <button
              type="submit"
              className="w-full bg-amber-700 text-white py-3 rounded hover:bg-amber-600 transition-colors"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="relative min-h-screen bg-gray-900 p-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-amber-300">Admin Panel</h1>
          <button
            onClick={handleLogout}
            className="px-4 py-2 bg-red-700 text-white rounded hover:bg-red-600"
          >
            Logout
          </button>
        </div>

        <div className="bg-gray-800 rounded-lg shadow-xl border-2 border-amber-700">
          <div className="flex border-b border-amber-700">
            <button
              onClick={() => setActiveTab('tournament')}
              className={`flex-1 py-4 px-6 text-lg font-semibold ${
                activeTab === 'tournament' ? 'bg-amber-700 text-white' : 'text-amber-300 hover:bg-amber-700/20'
              }`}
            >
              Tournament Management
            </button>
            <button
              onClick={() => setActiveTab('players')}
              className={`flex-1 py-4 px-6 text-lg font-semibold ${
                activeTab === 'players' ? 'bg-amber-700 text-white' : 'text-amber-300 hover:bg-amber-700/20'
              }`}
            >
              Player Management
            </button>
          </div>

          <div className="p-6">
            {error && (
              <div className="mb-4 p-3 bg-red-900/80 text-red-200 rounded">
                {error}
              </div>
            )}

            {activeTab === 'tournament' && (
              <div className="space-y-6">
                <div className="bg-gray-900/50 p-6 rounded-lg border border-amber-700/50">
                  <h3 className="text-xl font-semibold text-amber-300 mb-4">Add New Tournament Round</h3>
                  <div className="space-y-4">
                    <div>
                      <label className="block text-amber-200 mb-2">Round Name</label>
                      <input
                        type="text"
                        value={newRound.roundName}
                        onChange={(e) => setNewRound(prev => ({ ...prev, roundName: e.target.value }))}
                        className="w-full bg-gray-700 border-2 border-amber-700 rounded p-3 text-white focus:border-amber-500 focus:outline-none"
                        placeholder="Enter round name"
                      />
                    </div>
                    <div>
                      <label className="block text-amber-200 mb-2">Start Date</label>
                      <input
                        type="datetime-local"
                        value={newRound.startDate}
                        onChange={(e) => setNewRound(prev => ({ ...prev, startDate: e.target.value }))}
                        className="w-full bg-gray-700 border-2 border-amber-700 rounded p-3 text-white focus:border-amber-500 focus:outline-none"
                      />
                    </div>
                    <div>
                      <h4 className="text-lg text-amber-300 mb-4">Matches</h4>
                      {newRound.matches.map((match, index) => (
                        <div key={index} className="grid grid-cols-3 gap-4 mb-4">
                          <input
                            placeholder="Team 1"
                            value={match.team1}
                            onChange={(e) => updateMatch(index, 'team1', e.target.value)}
                            className="w-full bg-gray-700 border-2 border-amber-700 rounded p-3 text-white focus:border-amber-500 focus:outline-none"
                          />
                          <div className="flex items-center justify-center text-amber-300">VS</div>
                          <input
                            placeholder="Team 2"
                            value={match.team2}
                            onChange={(e) => updateMatch(index, 'team2', e.target.value)}
                            className="w-full bg-gray-700 border-2 border-amber-700 rounded p-3 text-white focus:border-amber-500 focus:outline-none"
                          />
                        </div>
                      ))}
                      <button
                        onClick={addNewMatch}
                        className="w-full py-3 mt-4 bg-amber-700/50 text-amber-300 rounded hover:bg-amber-700/70 transition-colors"
                      >
                        Add Match
                      </button>
                    </div>
                    <button
                      onClick={handleSaveRound}
                      className="w-full py-3 bg-amber-700 text-white rounded hover:bg-amber-600 transition-colors"
                    >
                      Save Round
                    </button>
                  </div>
                </div>

                <div className="bg-gray-900/50 p-6 rounded-lg border border-amber-700/50">
                  <h3 className="text-xl font-semibold text-amber-300 mb-4">Current Tournament</h3>
                  {tournamentData.map((round, index) => (
                    <div key={index} className="mb-6">
                      <h4 className="text-lg text-amber-300 mb-2">{round.roundName}</h4>
                      {round.matches.map((match, matchIndex) => (
                        <div key={matchIndex} className="grid grid-cols-3 gap-4 mb-4 bg-gray-800/50 p-3 rounded">
                          <div className="text-white">{match.team1}</div>
                          <div className="text-center text-amber-300">
                            {match.team1Score} - {match.team2Score}
                          </div>
                          <div className="text-white text-right">{match.team2}</div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {activeTab === 'players' && (
              <div className="space-y-6">
                <div className="bg-gray-900/50 p-4 rounded-lg border border-amber-700/50">
                  <h3 className="text-xl font-semibold text-amber-300 mb-4">Player Rankings</h3>
                  <div className="overflow-x-auto">
                    <table className="w-full">
                      <thead>
                        <tr className="border-b-2 border-amber-700">
                          <th className="text-left p-2 text-amber-300">Player</th>
                          <th className="text-left p-2 text-amber-300">Type</th>
                          <th className="text-left p-2 text-amber-300">Rank</th>
                          <th className="text-left p-2 text-amber-300">Wins</th>
                          <th className="text-left p-2 text-amber-300">Losses</th>
                          <th className="text-left p-2 text-amber-300">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {players.map((player) => (
                          <tr key={player._id} className="border-b border-amber-700/30">
                            <td className="p-2 text-white">{player.steamId}</td>
                            <td className="p-2 text-white">{player.playType}</td>
                            <td className="p-2 text-white">{player.rank}</td>
                            <td className="p-2 text-white">{player.wins}</td>
                            <td className="p-2 text-white">{player.losses}</td>
                            <td className="p-2">
                              <button
                                onClick={() => {
                                  setSelectedPlayer(player);
                                  setRankUpdate({
                                    rank: player.rank || '',
                                    wins: player.wins || '',
                                    losses: player.losses || ''
                                  });
                                }}
                                className="px-3 py-1 bg-amber-700 text-white rounded hover:bg-amber-600"
                              >
                                Edit
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                {selectedPlayer && (
                  <div className="bg-gray-900/50 p-4 rounded-lg border border-amber-700/50">
                    <h3 className="text-xl font-semibold text-amber-300 mb-4">
                      Update Player: {selectedPlayer.steamId}
                    </h3>
                    <div className="grid grid-cols-3 gap-4">
                      <div>
                        <label className="block text-amber-200 mb-2">Rank</label>
                        <input
                          type="number"
                          value={rankUpdate.rank}
                          onChange={(e) => setRankUpdate(prev => ({ ...prev, rank: e.target.value }))}
                          className="w-full bg-gray-700 border-2 border-amber-700 rounded p-2 text-white"
                        />
                      </div>
                      <div>
                        <label className="block text-amber-200 mb-2">Wins</label>
                        <input
                          type="number"
                          value={rankUpdate.wins}
                          onChange={(e) => setRankUpdate(prev => ({ ...prev, wins: e.target.value }))}
                          className="w-full bg-gray-700 border-2 border-amber-700 rounded p-2 text-white"
                        />
                      </div>
                      <div>
                        <label className="block text-amber-200 mb-2">Losses</label>
                        <input
                          type="number"
                          value={rankUpdate.losses}
                          onChange={(e) => setRankUpdate(prev => ({ ...prev, losses: e.target.value }))}
                          className="w-full bg-gray-700 border-2 border-amber-700 rounded p-2 text-white"
                        />
                      </div>
                    </div>
                    <div className="mt-4 flex justify-end gap-4">
                      <button
                        onClick={() => setSelectedPlayer(null)}
                        className="px-4 py-2 bg-gray-700 text-white rounded hover:bg-gray-600"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleUpdatePlayer}
                        className="px-4 py-2 bg-amber-700 text-white rounded hover:bg-amber-600"
                      >
                        Update Player
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;