// constants.js

export const API_URL = process.env.NODE_ENV === 'production' 
  ? 'https://api.knightshonor.fun'
  : 'http://localhost:5000';

export const ENDPOINTS = {
  REGISTER: '/api/register',
  PLAYERS: '/api/players',
  HEALTH: '/health',
  TOURNAMENT: '/api/tournament',
  LOGIN: '/api/admin/login'
};

export const DEFAULT_FETCH_OPTIONS = {
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Origin': window.location.origin
  }
};